import ApiService from "@/core/services/api.service";
import { responseError } from '@/core/mixin/shared'
import querystring from "querystring";

export const BASE_URL = 'api/messages';
export const MODULE_NAME = 'MESSENGER';
export const LOADING = `LOADING`;
export const ERROR = `ERROR`;
export const SUCCESS = `SUCCESS`;
export const ITEM = `ITEM`;
export const ITEMS = `ITEMS`;
export const SEARCH = `SEARCH`;
export const SEARCH_LOADING = `SEARCH_LOADING`;
export const USER_LIST = `USER_LIST`;
export const MESSAGE_GROUP = `MESSAGE_GROUP`;
export const MESSAGE_GROUP_LOADING = `MESSAGE_GROUP_LOADING`;
export const MESSAGE_GROUP_LIST = `MESSAGE_GROUP_LIST`;
export const MESSAGES = `MESSAGES`;
export const MESSAGE = `MESSAGE`;
export const MESSAGE_LOADING = `MESSAGE_LOADING`;
export const MESSAGES_LOADING = `MESSAGES_LOADING`;

export const SET_MESSAGE = `M/MESSAGE`;
export const SET_MESSAGE_LOADING = `M/MESSAGE_LOADING`;
export const SET_MESSAGES = `M/MESSAGES`;
export const SET_MESSAGES_LOADING = `M/MESSAGES_LOADING`;
export const SET_MESSAGE_GROUP_LIST = `M/MESSAGE_GROUP_LIST`;
export const SET_MESSAGE_GROUP_LOADING = `M/MESSAGE_GROUP_LOADING`;
export const SET_MESSAGE_GROUP = `M/MESSAGE_GROUP`;
export const SET_USER_LIST = `M/USER_LIST`;
export const SET_LOADING = `M/LOADING`;
export const SET_ERROR = `M/ERROR`;
export const SET_SUCCESS = `M/SUCCESS`;
export const SET_ITEMS = `M/ITEMS`;
export const SET_ITEM = `M/ITEM`;
export const SET_SEARCH = `M/SEARCH`;
export const SET_SEARCH_LOADING = `M/SEARCH_LOADING`;
export const RESET_VALUES = `M/RESET_VALUES`;

// ACTIONS
export const GET_ITEMS = `GET_ITEMS`;
export const GET_ITEM = `GET_ITEM`;
export const GET_MESSAGE = `GET_MESSAGE`;
export const GET_USER_LIST = `GET_USER_LIST`;
export const GET_SEARCH = `GET_SEARCH`;
export const UPDATE_ITEM = `UPDATE_ITEM`;
export const DELETE_ITEM = `DELETE_ITEM`;
export const CREATE_ITEM = `CREATE_ITEM`;
export const CREATE_MESSAGE_GROUP = `CREATE_MESSAGE_GROUP`;
export const CREATE_MESSAGE = `CREATE_MESSAGE`;
export const GET_MESSAGE_GROUPS = `GET_MESSAGE_GROUPS`;
export const GET_MESSAGES = `GET_MESSAGES`;

const state = {
    items: [],
    item: {},
    loading: false,
    error: null,
    success: null,
    search: null,
    search_loading: null,
    user_list: null,
    message_group: null,
    message_group_list: null,
    message_group_loading: null,
    messages: null,
    message: null,
    messages_loading: null,
    message_loading: null,
};

const getters = {
    [LOADING]: (state) => {
        return state.loading;
    },
    [ERROR]: (state) => {
        return state.error;
    },
    [SUCCESS]: (state) => {
        return state.success;
    },
    [ITEMS]: (state) => {
        return state.items;
    },
    [ITEM]: (state) => {
        return state.item;
    },
    [SEARCH]: (state) => {
        return state.search;
    },
    [SEARCH_LOADING]: (state) => {
        return state.search_loading;
    },
    [USER_LIST]: (state) => {
        return state.user_list;
    },
    [MESSAGE_GROUP]: (state) => {
        return state.message_group;
    },
    [MESSAGE_GROUP_LIST]: (state) => {
        return state.message_group_list;
    },
    [MESSAGE_GROUP_LOADING]: (state) => {
        return state.message_group_loading;
    },
    [MESSAGES]: (state) => {
        return state.messages;
    },
    [MESSAGES_LOADING]: (state) => {
        return state.messages_loading;
    },
    [MESSAGE]: (state) => {
        return state.message;
    },
    [MESSAGE_LOADING]: (state) => {
        return state.message_loading;
    },
};

const mutations = {
    [SET_LOADING]: (state, payload) => {
        state.loading = payload;
    },
    [SET_ERROR]: (state, payload) => {
        state.error = payload ? responseError(payload) : null;
    },
    [SET_ITEMS]: (state, payload) => {
        state.items = payload;
    },
    [SET_SUCCESS]: (state, payload) => {
        state.success = payload;
    },
    [SET_ITEM]: (state, payload) => {
        state.item = payload;
    },
    [SET_SEARCH_LOADING]: (state, payload) => {
        state.search_loading = payload;
    },
    [SET_SEARCH]: (state, payload) => {
        state.search = payload;
    },
    [SET_USER_LIST]: (state, payload) => {
        state.user_list = payload;
    },
    [SET_MESSAGE_GROUP]: (state, payload) => {
        state.message_group = payload;
    },
    [SET_MESSAGE_GROUP_LIST]: (state, payload) => {
        state.message_group_list = payload;
    },
    [SET_MESSAGE_GROUP_LOADING]: (state, payload) => {
        state.message_group_loading = payload;
    },
    [SET_MESSAGES]: (state, payload) => {
        state.messages = payload;
    },
    [SET_MESSAGES_LOADING]: (state, payload) => {
        state.messages_loading = payload;
    },
    [SET_MESSAGE]: (state, payload) => {
        state.message = payload;
    },
    [SET_MESSAGE_LOADING]: (state, payload) => {
        state.message_loading = payload;
    },
    [RESET_VALUES]: (state) => {
        state.success = null;
        state.error = null;
        state.loading = false;
        state.item = null;
    },
};

const actions = {
    [GET_ITEMS]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let filters = payload.filters;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.get(`${url}?` + querystring.stringify(filters))
            .then((response) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_ITEMS, response.data);
            })
            .catch((error) => {
                context.commit(SET_ERROR, error);
                context.commit(SET_LOADING, false);
            });
    },
    [GET_ITEM]: (context, payload) => {
        let url = payload.url || BASE_URL;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.get(url)
            .then((response) => {
                context.commit(SET_ITEM, response.data);
                context.commit(SET_LOADING, false);
            }).catch((error) => {
                context.commit(SET_ERROR, error);
                context.commit(SET_LOADING, false);
            });
    },
    [GET_SEARCH]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let filters = payload.filters;
        context.commit(SET_SEARCH_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.get(`${url}?` + querystring.stringify(filters))
            .then((response) => {
                context.commit(SET_SEARCH, response.data);
                context.commit(SET_SEARCH_LOADING, false);
            }).catch((error) => {
                context.commit(SET_ERROR, error);
                context.commit(SET_SEARCH_LOADING, false);
            });
    },
    [GET_USER_LIST]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let filters = payload.filters;
        context.commit(SET_SEARCH_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.get(`${url}?` + querystring.stringify(filters))
            .then((response) => {
                context.commit(SET_USER_LIST, response.data);
                context.commit(SET_SEARCH_LOADING, false);
            }).catch((error) => {
                context.commit(SET_ERROR, error);
                context.commit(SET_SEARCH_LOADING, false);
            });
    },
    [GET_MESSAGE_GROUPS]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let filters = payload.filters;
        context.commit(SET_SEARCH_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.get(`${url}?` + querystring.stringify(filters))
            .then((response) => {
                console.log('settt',response.data);
                context.commit(SET_MESSAGE_GROUP_LIST, response.data);
                context.commit(SET_SEARCH_LOADING, false);
            }).catch((error) => {
                context.commit(SET_ERROR, error);
                context.commit(SET_SEARCH_LOADING, false);
            });
    },
    [CREATE_MESSAGE_GROUP]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let contents = payload.contents;
        context.commit(SET_MESSAGE_GROUP_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.post(url,contents)
            .then((response) => {
                context.commit(SET_MESSAGE_GROUP, response.data);
                context.commit(SET_MESSAGE_GROUP_LOADING, false);

                return {status: true, data: response.data}
            }).catch((error) => {
                context.commit(SET_ERROR, error);
                context.commit(SET_MESSAGE_GROUP_LOADING, false);
            });
    },
    [CREATE_MESSAGE]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let contents = payload.contents;
        context.commit(SET_MESSAGE_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.post(url,contents)
            .then((response) => {
                context.commit(SET_MESSAGE, response.data);
                context.commit(SET_MESSAGE_LOADING, false);
            }).catch((error) => {
                context.commit(SET_ERROR, error);
                context.commit(SET_MESSAGE_LOADING, false);
            });
    },
    [GET_MESSAGES]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let filters = payload.filters;
        context.commit(SET_MESSAGES_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.get(`${url}?` + querystring.stringify(filters))
            .then((response) => {
                context.commit(SET_MESSAGES, response.data);
                context.commit(SET_MESSAGES_LOADING, false);
            }).catch((error) => {
                context.commit(SET_ERROR, error);
                context.commit(SET_MESSAGES_LOADING, false);
            });
    },
    [CREATE_ITEM]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let contents = payload.contents;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.post(url,contents)
            .then(() => {
                context.commit(SET_ITEM, {});
                context.commit(SET_LOADING, false);
            }).catch((error) => {
                context.commit(SET_ERROR, error);
                context.commit(SET_LOADING, false);
            });
    },
    [DELETE_ITEM]: (context, payload) => {
        let url = payload.url || BASE_URL;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.delete(url)
            .then(() => {
                context.commit(SET_ITEM, {});
                context.commit(SET_LOADING, false);
            }).catch((error) => {
                context.commit(SET_ERROR, error);
                context.commit(SET_LOADING, false);
            });
    },
    [UPDATE_ITEM]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let contents = payload.contents;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.put(url, contents)
            .then((response) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_ITEM, response.data);
            }).catch((error) => {
                context.commit(SET_ERROR, error);
                context.commit(SET_LOADING, false);
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
